import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Login from "./_pages/Login/login";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Landing from "./_pages/Landing/landing";
import PrivacyPolicy from "./_pages/PrivacyPolicy/index";

const test = () => {
  return <h1>Test</h1>;
};

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/privacy-policy" exact component={PrivacyPolicy} />
      <Route path="/login" exact component={Login} />
      <Route path="/" component={Landing} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
