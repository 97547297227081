import React, { useState } from "react";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import "./Footer.styles.scss";

const Footer = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div
      className="web-footer-container"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <p style={{ fontWeight: 300, cursor: "pointer" }} onClick={showModal}>
        Forget password?
      </p>
      <Modal
        title="Forget password?"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        To reset your password, please contact your account manager
      </Modal>

      <Link
        to={"/privacy-policy"}
        target="_blank"
        className="nav-link"
        style={{ marginTop: 20 }}
      >
        <p>Privacy policy</p>
      </Link>
    </div>
  );
};

export default Footer;
