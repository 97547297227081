import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Kpi from "./../_pages/HrModule/KPI/kpi";
import Attendance from "./../_pages/HrModule/Attendance/attendance";
import Loading from "../content/spin";
import Loadable from "react-loadable";
import { Call_validation_users } from "./menuList";

// const LoadableDashboard = Loadable({
//   loader: () => import("./../_pages/Dashboard/dashboard"),
//   loading() {
//     return <Loading />
//   },
// })

const LoadableDailyRoutePlan = Loadable({
  loader: () => import("../Pages/DailyRoutePlan"),
  loading() {
    return <Loading />;
  },
});

const LoadableNewDashboard = Loadable({
  loader: () => import("./../_pages/NewDashboard/newDashboard"),
  loading() {
    return <Loading />;
  },
});

const LoadableLeaveManagement = Loadable({
  loader: () => import("../_pages/LeaveManagement/leaveManagement.page"),
  loading() {
    return <Loading />;
  },
});

const LoadableFieldActivity = Loadable({
  loader: () => import("./../_pages/DataVizualization/fieldActivity"),
  loading() {
    return <Loading />;
  },
});

const LoadableSOV = Loadable({
  loader: () => import("./../_pages/DataVizualization/SOV"),
  loading() {
    return <Loading />;
  },
});

const LoadableTemp = Loadable({
  loader: () => import("./../_pages/DataVizualization/Temp"),
  loading() {
    return <Loading />;
  },
});

const LoadableQRCount = Loadable({
  loader: () => import("./../_pages/DataVizualization/qrCount"),
  loading() {
    return <Loading />;
  },
});

const LoadableQRTrend = Loadable({
  loader: () => import("./../_pages/DataVizualization/qrTrend"),
  loading() {
    return <Loading />;
  },
});

const LoadableMerchandiserCalls = Loadable({
  loader: () => import("./../_pages/DataVizualization/merchandiserCalls"),
  loading() {
    return <Loading />;
  },
});

const LoadableSupervisorCalls = Loadable({
  loader: () => import("./../_pages/DataVizualization/supervisorCalls"),
  loading() {
    return <Loading />;
  },
});

const LoadableBroken = Loadable({
  loader: () => import("./../component/brokenPage"),
  loading() {
    return <Loading />;
  },
});

const LoadableTotalOutletSummary = Loadable({
  loader: () => import("../_pages/TotalOutletSummary/totalOutletSummary"),
  loading() {
    return <Loading />;
  },
});

const LoadableTodaysCallSummary = Loadable({
  loader: () => import("./../_pages/TodaysCallSummary/todaysCallSummary"),
  loading() {
    return <Loading />;
  },
});

const LoadableMerchandisers = Loadable({
  loader: () => import("./../_pages/Merchandisers/merchandisers"),
  loading() {
    return <Loading />;
  },
});

const LoadableMerchandiserProfile = Loadable({
  loader: () => import("./../_pages/MerchandiserProfile/merchandiserProfile"),
  loading() {
    return <Loading />;
  },
});

const LoadableReports = Loadable({
  loader: () => import("./../_pages/Reports/reports"),
  loading() {
    return <Loading />;
  },
});

const LoadableRoutePlan = Loadable({
  loader: () => import("./../_pages/_RoutePlan/routePlan"),
  loading() {
    return <Loading />;
  },
});

const LoadableLandmark = Loadable({
  loader: () => import("./../_pages/Landmark/routePlan"),
  loading() {
    return <Loading />;
  },
});

const LoadableOutlets = Loadable({
  loader: () => import("./../Pages/Outlets"),
  loading() {
    return <Loading />;
  },
});

const LoadableImageRecognition = Loadable({
  loader: () => import("./../_pages/ImageRecognition/imageRecognition"),
  loading() {
    return <Loading />;
  },
});

const LoadableImageOutletProfile = Loadable({
  // loader: () => import("./../_pages/OutletsProfile/outletProfile"),
  loader: () => import("../Pages/OutletProfile"),
  loading() {
    return <Loading />;
  },
});

const LoadableImageGallery = Loadable({
  loader: () => import("./../_pages/ImageGallery/imageGallery"),
  loading() {
    return <Loading />;
  },
});

const LoadableLatestCall = Loadable({
  loader: () => import("./../_pages/LatestCall/latestCall"),
  loading() {
    return <Loading />;
  },
});

const LoadableLatestCallValidation = Loadable({
  loader: () => import("./../_pages/LatestCallValidation/latestCallValidation"),
  loading() {
    return <Loading />;
  },
});

const LoadableSelfie = Loadable({
  loader: () => import("./../_pages/SelfieAttendance/selfieAttendance"),
  loading() {
    return <Loading />;
  },
});

const LoadableUploadRoute = Loadable({
  loader: () => import("./../_pages/UploadRoute/uploadRoute"),
  loading() {
    return <Loading />;
  },
});

const LoadableLeaveAbsent = Loadable({
  loader: () => import("./../_pages/LeaveAbsent/leaveAbsent"),
  loading() {
    return <Loading />;
  },
});

const _LoadablePOSM = Loadable({
  loader: () => import("./../Pages/Posm"),
  loading() {
    return <Loading />;
  },
});

const LoadablePOSMPortal = Loadable({
  loader: () => import("./../Pages/PosmPortal"),
  loading() {
    return <Loading />;
  },
});

const LoadableInventoryPortal = Loadable({
  loader: () => import("./../Pages/InventoryPortal"),
  loading() {
    return <Loading />;
  },
});

const LoadablePOSMDashboard = Loadable({
  loader: () => import("./../Pages/POSMDashboard"),
  loading() {
    return <Loading />;
  },
});

const _404 = Loadable({
  loader: () => import("./../_pages/404/404"),
  loading() {
    return <Loading />;
  },
});

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: true,
    };
  }

  componentWillMount() {
    let _this = this;
    if (localStorage.getItem("token")) {
      _this.setState({
        isAuthenticated: true,
        loading: false,
      });
    } else {
      _this.setState({
        isAuthenticated: false,
      });
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) =>
          this.state.isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )
        }
      />
    );
  }
}

export default function getSelectedContent(selectedMenu) {
  return (
    <Switch>
      <Route exact path="/field_activity" component={LoadableFieldActivity} />
      <Route exact path="/qr_count" component={LoadableQRCount} />
      <Route exact path="/qr_trend" component={LoadableQRTrend} />
      <Route
        exact
        path="/merchandiser_calls"
        component={LoadableMerchandiserCalls}
      />
      <Route
        exact
        path="/supervisor_calls"
        component={LoadableSupervisorCalls}
      />

      <Route
        exact
        path="/total_calls"
        component={
          localStorage.getItem("user_type").toUpperCase() === "RM" ||
          localStorage.getItem("user_type").toUpperCase() === "AM" ||
          localStorage.getItem("user_type").toUpperCase() === "TM" ||
          localStorage.getItem("user_type").toUpperCase() === "DM"
            ? LoadableBroken
            : LoadableTotalOutletSummary
        }
      />

      <Route
        exact
        path="/todays_calls"
        component={
          localStorage.getItem("user_type").toUpperCase() === "RM" ||
          localStorage.getItem("user_type").toUpperCase() === "AM" ||
          localStorage.getItem("user_type").toUpperCase() === "TM" ||
          localStorage.getItem("user_type").toUpperCase() === "DM"
            ? LoadableBroken
            : LoadableTodaysCallSummary
        }
      />
      <Route exact path="/field_force" component={LoadableMerchandisers} />
      <Route
        path="/field_force/:whitelisted_number"
        component={LoadableMerchandiserProfile}
      />
      <Route path="/reports" component={LoadableReports} />
      <Route exact path="/route_plan" component={LoadableRoutePlan} />
      <Route exact path="/landmark" component={LoadableLandmark} />
      <Route exact path="/outlets" component={LoadableOutlets} />
      <Route
        exact
        path="/image_recognition"
        component={LoadableImageRecognition}
      />
      <Route
        path="/outlets/:wallet_number"
        component={LoadableImageOutletProfile}
      />
      <Route
        path="/image_gallery/:wallet_number"
        component={LoadableImageGallery}
      />
      <Route exact path="/calls" component={LoadableLatestCall} />
      {Call_validation_users.includes(localStorage.getItem("user_name")) && (
        <Route
          exact
          path="/calls-validation"
          component={LoadableLatestCallValidation}
        />
      )}
      <Route exact path="/selfie_attendance" component={LoadableSelfie} />
      <Route exact path="/upload_route_plan" component={LoadableUploadRoute} />
      <Route exact path="/leave_absent" component={LoadableLeaveAbsent} />
      <Route exact path="/sov" component={LoadableSOV} />
      <Route exact path="/temp" component={LoadableTemp} />

      <Route exact path="/kpi" component={Kpi} />

      <Route
        exact
        path="/leave_management"
        component={
          localStorage.getItem("user_type").toUpperCase() === "RMM"
            ? LoadableLeaveManagement
            : LoadableBroken
        }
      />

      <Route exact path="/attendance" component={Attendance} />

      {/* <Route exact path="/" component={LoadableDashboard} /> */}

      <Route exact path="/" component={LoadableNewDashboard} />
      {/* <Route exact path="/new_dashboard" component={LoadableNewDashboard} /> */}

      <Route exact path="/posm_reporting" component={_LoadablePOSM} />
      <Route exact path="/posm_portal" component={LoadablePOSMPortal} />
      <Route
        exact
        path="/daily_route_plan"
        component={LoadableDailyRoutePlan}
      />
      <Route
        exact
        path="/inventory_portal"
        component={LoadableInventoryPortal}
      />
      <Route exact path="/posm_dashboard" component={LoadablePOSMDashboard} />

      <Route component={_404} />
    </Switch>
  );
}
