import React from "react";
import Table from "./_tempTable";
import Generator from "./../../../../dataProvider/_generator";
import axios from "axios";

export default class SimpleTable extends React.Component {
  componentDidMount() {
    var url = Generator(this.props.link, this.props.value);

    axios
      .get(url, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((res) => this.props.updateFunction(this.props.updateVar, res.data))
      .catch((error) => console.log(error));
  }

  render() {
    return (
      <React.Fragment>
        {this.props.onChange ? (
          <Table
            data={this.props.data}
            columns={this.props.col}
            pagination={this.props.pagination}
            onChange={this.props.onChange}
          />
        ) : (
          <Table
            data={this.props.data}
            columns={this.props.col}
            pagination={this.props.pagination}
          />
        )}
      </React.Fragment>
    );
  }
}
