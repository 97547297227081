import React from "react";
import { Upload, Button, Icon, message, Form, Alert } from "antd";
import { fileRead } from "./../utility/fileRead";
import Axios from "axios";
import API from "./../../../../dataProvider/api_list.json";
import moment from "moment";

export default class FileUpload extends React.Component {
  state = {
    loading: false,
    showUploadButton: true,
    showUploadList: false,
    user_list: [],
    attendanceStatus: "Present",
    wrong_data: [],
    selectedDate: moment(new Date()).format("DD/MM/YYYY"),
    fileList: [],
    clean_data: [],
  };

  beforeUpload = (file) => {
    var findColumnList = ["Whitelisting Number", "Status"];
    var attendenceStatus = [
      "Present",
      "Apps Problem",
      "Handset Problem",
      "Handset Lost",
      "Natural Calamity",
      "Leave",
      "Vacant",
      "Absent",
      "Field Attachment",
      "On Call Duty",
      "Government Restriction",
    ];

    new Promise((resolve) => {
      resolve({ fileStatus: fileRead(file, findColumnList) });
    }).then((result) => {
      result.fileStatus.then((res) => {
        //console.log(res)
        if (res === false) message.error("Column Not Found");
        else {
          //console.log(res)
          this.setState({ showUploadList: true });
          let _temp = res.map((item, index) => {
            var prefix = item
              ? item.cell_1.v.toString().substr(-10)
              : "0000000000";
            return {
              whitelisting_number: prefix,
              attendance_status: item
                ? attendenceStatus.find(
                    (e) => e.toLowerCase() === item.cell_2.v.toLowerCase()
                  ) !== undefined
                  ? item.cell_2.v.toUpperCase()
                  : item.cell_1.v.toString().substr(-10)
                : "",
            };
          });
          this.setState({ user_list: _temp });
          var _clean_data = [];
          var _wrong_data = [];

          _temp.map((user) =>
            user.whitelisting_number === user.attendance_status
              ? _wrong_data.push(user)
              : _clean_data.push(user)
          );

          this.setState({ wrong_data: _wrong_data });
          this.setState({ clean_data: _clean_data });
        }
      });
    });
  };
  handleChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    this.setState({ fileList });
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    //console.log(info)
  };

  handleAttendanceStatusChange = (value) =>
    this.setState({ attendanceStatus: value });

  handleChangeDate = (value) => {
    //console.log(value)
    this.setState({ selectedDate: moment(value).format("DD/MM/YYYY") });
  };

  handleRemove = () => {
    this.setState({ showUploadList: false });
    this.setState({ user_list: [] });
    this.setState({ fileList: [] });
  };

  removeAlert = () => {
    this.setState({ wrong_data: [] });
    this.setState({ fileList: [] });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //console.log(this.state.user_list)
    if (this.state.user_list.length > 0) {
      var loading = message.loading("Uploading...", 0);
      var formData = new FormData();
      this.props.update("attendanceActionTableData", []);
      //console.log(this.state.user_list)
      //console.log(JSON.stringify(this.state.user_list))

      formData.append("attendance_data", JSON.stringify(this.state.clean_data));

      Axios.post(
        API.HOST + API.UPDATE_ATTENDANCE_STATUS + "/" + this.state.selectedDate,
        formData,
        {
          headers: { Authorization: "JWT " + localStorage.getItem("token") },
        }
      )
        .then((res) => {
          setTimeout(loading, 0);
          this.props.update("attendanceActionTableData", res.data.data);
          this.handleRemove();
        })
        .catch((error) => {
          setTimeout(loading, 0);
          message.error("Data Not Found");
        });
    } else {
      message.error(
        "Please Upload a Valid xlsx File With Column `Whitelisting Number`"
      );
    }

    //console.log(this.state)
  };

  render() {
    return this.state.wrong_data.length > 0 ? (
      <Alert
        message="Please provide a valid attendance status"
        description={
          <div>
            <span>Total {this.state.wrong_data.length} error found</span>
            <ol>
              {this.state.wrong_data.map((wd) => (
                <li>+880{wd.whitelisting_number.substr(-10)}</li>
              ))}
            </ol>
          </div>
        }
        type="error"
        closable
        onClose={this.removeAlert}
      />
    ) : (
      <div style={{ padding: "40px 0 40px 30px" }}>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            <Upload
              name="avatar"
              listType="picture-card"
              className="file-upload"
              showUploadList={this.state.showUploadList}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={this.beforeUpload}
              onChange={this.handleChange}
              onRemove={this.handleRemove}
              fileList={this.state.fileList}
            >
              {this.state.showUploadButton ? (
                <Button className="file-upload-button">
                  <Icon type="upload" />
                  Upload
                </Button>
              ) : (
                ""
              )}
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button
              className="submit-button"
              style={{ width: "250px" }}
              htmlType="submit"
            >
              Upload Attendance File
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
