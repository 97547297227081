import React from "react";
import moment from "moment";

export const DataContext = React.createContext();
export const DataConsumer = DataContext.Consumer;

class DataProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMenu: "0",

      today: moment(new Date()).format("DD/MM/YYYY"),
      yesterday: moment(new Date()).add(-1, "days").format("DD/MM/YYYY"),
      currMonth: moment(new Date()).format("MM/YYYY"),
      preMonth: moment(new Date()).add(-1, "months").format("MM/YYYY"),

      update: this.update.bind(this),
      filter: this.filter.bind(this),
      getRegion: this.getRegion.bind(this),
      getArea: this.getArea.bind(this),
      getTerritory: this.getTerritory.bind(this),
      getDistributionHouse: this.getDistributionHouse.bind(this),
      getUserType: this.getUserType.bind(this),
      getWhitelistedNumber: this.getWhitelistedNumber.bind(this),

      dashUserOverview_MERCHANDISER: [],
      dashUserOverview_SUPERVISOR: [],

      activeMerchandisersTotal: "",
      activeMerchandisersToday: "",
      activeMerchandisersYesterday: "",
      selfieCountMerchandiser: "",
      leaveAbsentMerchandiser: "",
      activeSupervisorsTotal: "",
      activeSupervisorsToday: "",
      activeSupervisorsYesterday: "",
      selfieCountSupervisor: "",
      leaveAbsentSupervisor: "",

      totalUniqueOutlets: "",

      openUniqueOutletsToday: "",
      closedUniqueOutletsToday: "",
      notFoundUniqueOutletsToday: "",
      inactiveUniqueOutletsToday: "",
      openUniqueOutletsPreMonth: "",
      closedUniqueOutletsPreMonth: "",
      notFoundUniqueOutletsPreMonth: "",
      inactiveUniqueOutletsPreMonth: "",
      openUniqueOutletsThisMonth: "",
      closedUniqueOutletsThisMonth: "",
      notFoundUniqueOutletsThisMonth: "",
      inactiveUniqueOutletsThisMonth: "",

      activeMerchandisersTable: "",
      activeSupervisorsTable: "",

      totalOutletSummaryMerchandisersTable: "",
      totalOutletSummarySupervisorsTable: "",

      todaysCallSummaryTable: "",

      merchandiser: "",
      searchMerchandiser: "",
      searchMerchandiserDrawer: "",
      supervisor: "",

      searchOutlet: "",
      searchOutletDrawer: false,
      outletPhotos: [],

      region: "0",
      area: "0",
      territory: "0",
      house: "0",
      user: "0",

      outletType: "0",
      agent_merchant: "0",
      bmcc: "0",
      outlet: "0",
      whitelisting_number: "0",
      business_pillar: "0",
      call_type: "0",

      filterMerchandisers: [],
      _filterMerchandisers: [],

      filterRegion: [],
      filterRegionPermission: true,
      filterArea: [],
      filterAreaPermission: true,
      filterTerritory: [],
      filterTerritoryPermission: true,
      filterDistributionHouse: [],
      filterDistributionHousePermission: true,
      filterUserType: [],
      filterUserTypePermission: true,
      filterWhitelistedNumber: [],
      filterWhitelistedNumberPermission: true,

      dateFrom: moment(new Date()).format("DD/MM/YYYY"),
      dateTo: moment(new Date()).format("DD/MM/YYYY"),
      limit: "50",
      offset: "0",

      selectedFilterType: "",
      latestCallApi: "",
      latestCall: "",
      latestCallDetails: "",
      latestCallDetailsDrawer: false,
      latestCallPageCount: "0",

      fileUploadLatestCallData: "",
      selectedDateFromFileUploadLatestCall: "",
      selectedDateToFileUploadLatestCall: "",

      selectedMerchandiser: "",
      preMerchandiser: [],
      preCall: [],

      userWhitelistingNumberActive: false,
      userWhitelistingNumberLoading: false,
      userWhitelistingNumber: [],
      selectedOutletsType: "0",

      merProBasicInfo: "",
      merProActivenessInfo: "",
      merProHistory: "",

      selfie: "",
      attendanceHistory: [],
      attendanceActionTableData: [],
      kpi: [],

      allMerchandiserWhitelistedNumber: "",
      routePlanDate: "",
      routePlanSelectedMerchandiser: "",
      routePlan: "",

      routePlanSpecificMer: "",

      fileUploadOutletData: "",
      disableDowloadReport: true,
      selectedDateFromFileUpload: "",
      selectedDateToFileUpload: "",

      fileUploadOutletData_outlet_status_checker: "",
      disableDowloadReport_outlet_status_checker: true,
      selectedDateFromFileUpload_outlet_status_checker: "",
      selectedDateToFileUpload_outlet_status_checker: "",

      paginationPermission: true,

      image_recognition_selected_image: "",

      latestCallPdf: [],

      outlet_status_checker_permission: true,
      calls_filter_modal_visible: false,

      latestCallValidation: "",
    };
  }

  update(key, value) {
    // //console.log(key, value);
    this.setState({ [key]: value });
  }

  filter(filterMerchandisers, filter, value) {
    if (filterMerchandisers.length > 0) {
      // //console.log('ok')
      var _temp = [];
      // //console.log()
      if (value !== "0") {
        // //console.log(this.state.region)
        filterMerchandisers.map((item) =>
          item[`${filter}`].includes(value) ? _temp.push(item) : ""
        );
      } else {
        _temp = filterMerchandisers;
      }
      //console.log(_temp);
      this.setState({ filterMerchandisers: _temp });
      if (filter === "Region") {
        // //console.log('Region')
        // //console.log(this.getArea(_temp))
        this.setState({ filterArea: this.getArea(_temp) });
        this.setState({ filterTerritory: this.getTerritory(_temp) });
        this.setState({
          filterDistributionHouse: this.getDistributionHouse(_temp),
        });
        this.setState({ filterUserType: this.getUserType(_temp) });
        this.setState({
          filterWhitelistedNumber: this.getWhitelistedNumber(_temp),
        });
      } else if (filter === "Area") {
        this.setState({ filterTerritory: this.getTerritory(_temp) });
        this.setState({
          filterDistributionHouse: this.getDistributionHouse(_temp),
        });
        this.setState({ filterUserType: this.getUserType(_temp) });
        this.setState({
          filterWhitelistedNumber: this.getWhitelistedNumber(_temp),
        });
      } else if (filter === "Territory") {
        this.setState({
          filterDistributionHouse: this.getDistributionHouse(_temp),
        });
        this.setState({ filterUserType: this.getUserType(_temp) });
        this.setState({
          filterWhitelistedNumber: this.getWhitelistedNumber(_temp),
        });
      } else if (filter === "Distribution_House") {
        this.setState({ filterUserType: this.getUserType(_temp) });
        this.setState({
          filterWhitelistedNumber: this.getWhitelistedNumber(_temp),
        });
      } else if (filter === "user_type") {
        this.setState({
          filterWhitelistedNumber: this.getWhitelistedNumber(_temp),
        });
      }
    }
  }

  getRegion(filterMerchandisers) {
    return [...new Set(filterMerchandisers.map((x) => x.Region))];
  }
  getArea(filterMerchandisers) {
    return [...new Set(filterMerchandisers.map((x) => x.Area))];
  }
  getTerritory(filterMerchandisers) {
    return [...new Set(filterMerchandisers.map((x) => x.Territory))];
  }
  getDistributionHouse(filterMerchandisers) {
    return [...new Set(filterMerchandisers.map((x) => x.Distribution_House))];
  }
  getUserType(filterMerchandisers) {
    return [...new Set(filterMerchandisers.map((x) => x.user_type))];
  }
  getWhitelistedNumber(filterMerchandisers) {
    return [...new Set(filterMerchandisers.map((x) => x.Merchandiser_Number))];
  }
  getCallType() {
    return [
      {
        title: "Regular",
        value: "regular",
      },
      {
        title: "Add Hoc",
        value: "ad_hoc",
      },
      {
        title: "Campaign",
        value: "campaign",
      },
      {
        title: "Validation",
        value: "validation",
      },
      {
        title: "Specific Outlet Call",
        value: "specific_outlet_call",
      },
    ];
  }
  filterDataUpdate(filterMerchandisers) {}

  render() {
    return (
      <DataContext.Provider value={this.state}>
        {this.props.children}
      </DataContext.Provider>
    );
  }
}

export default DataProvider;

//temp
//temp
//temp
