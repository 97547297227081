import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyC6EA2wm4pdzI1qi3Lf9Ta2a6V8yhhe_S0",
    authDomain: "bkashbiponon.firebaseapp.com",
    databaseURL: "https://bkashbiponon-dev.firebaseio.com",
    projectId: "bkashbiponon",
    storageBucket: "bkashbiponon.appspot.com",
    messagingSenderId: "840583541349",
    appId: "1:840583541349:web:3b4bebc9094413f002cc8c",
    measurementId: "G-Y8ZPMFPC8J"
}

firebase.initializeApp(config)

export default firebase