import React from "react";
import { Row, Col, Button, Icon, Input, Avatar } from "antd";
import { DataConsumer } from "../../../../dataProvider/controller";
import Table from "./_tempSimpleTable";
import moment from "moment";
import LinkButton from "./../../../../component/linkButton";
import Search from "./_tempComponent";
import Highlighter from "react-highlight-words";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class AttendanceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      today: moment(new Date()).format("DD/MM/YYYY"),
      selectedDateFrom: moment(new Date()).format("DD/MM/YYYY"),
      selectedDateTo: moment(new Date()).format("DD/MM/YYYY"),
    };
  }

  handleDateRange = (from, to) =>
    this.setState({
      selectedDateFrom: from,
      selectedDateTo: to,
    });

  handleLimit = (rules, value, callback) => {
    if (value !== undefined) {
      if (
        parseInt(moment.duration(value[1].diff(value[0])).asDays() + 1) <=
        this.props.limit
      ) {
        callback();
        return;
      }
      callback("Date Range Cross The Limit");
      return;
    }
    callback("Date Range Cross The Limit");
    return;
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          className="submit-button"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
    });
  };

  handleColumn = () => {};

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    this.handleColumn();
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        className: "name",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Whitelisted Number",
        dataIndex: "whitelisting_number",
        key: "whitelisting_number",
        className: "whitelisting_number",
        ...this.getColumnSearchProps("whitelisting_number"),
        render: (whitelisting_number) => (
          <span>
            <LinkButton
              prefix={"field_force"}
              id={whitelisting_number}
              name={<FontAwesomeIcon icon="user" />}
            />
            {whitelisting_number}
          </span>
        ),
      },
      {
        title: "Region",
        dataIndex: "region",
        key: "region",
        className: "region",
        filters: [
          { text: "BARISAL", value: "BARISAL" },
          { text: "BOGRA", value: "BOGRA" },
          { text: "CHITTAGONG", value: "CHITTAGONG" },
          { text: "COMILLA", value: "COMILLA" },
          { text: "DHAKA SOUTH", value: "DHAKA SOUTH" },
          { text: "DHAKA NORTH", value: "DHAKA NORTH" },
          { text: "KHULNA", value: "KHULNA" },
          { text: "MYMENSINGH", value: "MYMENSINGH" },
          { text: "SYLHET", value: "SYLHET" },
          { text: "RANGPUR", value: "RANGPUR" },
        ],
        filteredValue: filteredInfo.region || null,
        onFilter: (value, record) => record.region.includes(value),
      },
      {
        title: "Area",
        dataIndex: "area",
        key: "area",
        className: "area",
      },
      {
        title: "Territory",
        dataIndex: "territory",
        key: "territory",
        className: "territory",
      },
      {
        title: "Distribution House",
        dataIndex: "distributor_code",
        key: "distributor_code",
        className: "distributor_code",
      },
      {
        title: "Attendance Status",
        dataIndex: { ...this.state.selectedDate },
        key: "attendance_status",
        className: "attendance_status",
      },
    ];

    const _columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        className: "name",
      },
      {
        title: "Whitelisted Number",
        dataIndex: "whitelisting_number",
        key: "whitelisting_number",
        className: "whitelisting_number",
      },
      {
        title: "Region",
        dataIndex: "region",
        key: "region",
        className: "region",
      },
      {
        title: "Area",
        dataIndex: "area",
        key: "area",
        className: "area",
      },
      {
        title: "Territory",
        dataIndex: "territory",
        key: "territory",
        className: "territory",
      },
      {
        title: "Distribution House",
        dataIndex: "distributor_code",
        key: "distributor_code",
        className: "distributor_code",
      },
      {
        title: "Attendance Status",
        dataIndex: "Attendance Status",
        key: "attendance_status",
        className: "attendance_status",
      },
    ];

    return (
      <DataConsumer>
        {({ attendanceHistory, update }) => {
          var _temp = attendanceHistory.table_column || [];
          var newCol =
            _temp.length > 0
              ? _temp.map((item, index) => {
                  //console.log(attendanceHistory)
                  return {
                    title: item
                      .replace("_", " ")
                      .split(" ")
                      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                      .join(" "),
                    dataIndex: item,
                    key: index,
                    className: item,
                  };
                })
              : [];
          return (
            <React.Fragment>
              <Row className="table-container">
                {/* <Row className="title-conatiner">
                <Col xs={24} sm={24} md={18} lg={18}>
                  <h3>Attendance History</h3>
                </Col>
              </Row> */}
                <Row className="form-date-range-elasticsearch">
                  <Search
                    updateFunction={update}
                    updateVar="attendanceHistory"
                    apiName="ATTENDANCE_ACTION_TABLE"
                    user="0"
                    limit="31"
                    handleDateRange={this.handleDateRange}
                  />
                </Row>
                <Table
                  updateFunction={update}
                  updateVar="attendanceHistory"
                  link="ATTENDANCE_ACTION_TABLE"
                  value={[this.state.today, this.state.today, "0"]}
                  data={attendanceHistory.data}
                  col={newCol || _columns}
                  pagination={true}
                  onChange={this.handleChange}
                />
              </Row>
            </React.Fragment>
          );
        }}
      </DataConsumer>
    );
  }
}
