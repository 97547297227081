import axios from "axios";

//custom axios interceptor for inventory portal

const axiosApiInstance = axios.create();
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: "JWT " + localStorage.getItem("token"),
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    //Access token expired
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        //requesting new access token with refresh token
        const refresh = localStorage.getItem("refresh_token");
        const response = await axios.post(
          "https://biponon-management.intelligentmachines.xyz/bkash-biponon/auth/token/refresh",
          { refresh }
        );
        const token = response.data.access;

        localStorage.setItem("token", token);

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `JWT ${token}`;
        return axios(originalRequest);
      } catch (error) {
        //signout and redirects to
        localStorage.clear();
        window.location.pathname = "/login";
        // Handle refresh token error or redirect to login
      }
    }
    return Promise.reject(error);
  }
);

export default axiosApiInstance;
