import React from "react";
import MySider from "../content/sider";
import Header from "../content/header";
import DataProvider from "../dataProvider/controller";
import { DataConsumer } from "../dataProvider/controller";
import { Layout } from "antd";
import { withRouter } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";

import firebase from "./../firebase";

import "./style/component.css";
import Axios from "axios";
import AppLogout from "../Layout/AppLogout";
class MainFrame extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then((token) => {
        Axios.post(
          `https://us-central1-bkashbiponon.cloudfunctions.net/sendFCMTokenToFirebase?id=${localStorage.getItem(
            "user_type"
          )}&fcm_token=${token}`
        );
      })
      .catch(() => console.log("error"));
  }

  render() {
    return (
      <DataProvider>
        <Layout style={{ height: "100vh" }}>
          {/*AppLogout fot auto logout after 30mins*/}
          <AppLogout>
            <Header {...this.props} />
            <DataConsumer>
              {({ update }) => (
                <MySider {...this.props} updateFunction={update} />
              )}
            </DataConsumer>
          </AppLogout>
        </Layout>
      </DataProvider>
    );
  }
}

export default withRouter(MainFrame);
