import React from "react";
import { Row } from "antd";
import "./../style/spin.css";

export default class Loading extends React.Component {
  render() {
    return (
      <Row className="loading-conatiner">
        <div className="loading" />
      </Row>
    );
  }
}
