import React from "react";
import { Row, Button, Icon, Input, Select, Collapse, message } from "antd";
import { DataConsumer } from "../../../../dataProvider/controller";
import Table from "./../../../../component/simpleTable";
import moment from "moment";
import LinkButton from "./../../../../component/linkButton";
import Search from "./../../../../component/dateUserELasticSearch";
import FileUpload from "./attendanceFileUpload";
import Highlighter from "react-highlight-words";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Api from "./../../../../dataProvider/api_list.json";

const { Panel } = Collapse;
export default class AttendanceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      today: moment(new Date()).format("DD/MM/YYYY"),
      today_chk: moment(new Date()).format("YYYY-MM-DD"),
      user_list: [],
      selectedDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedUserType: "0",
      selectedDateApiDate: moment(new Date()).format("DD/MM/YYYY"),
    };
  }

  changeSelectedDate = (x) => {
    //console.log(x)
    this.setState({ selectedDate: moment(x).format("YYYY-MM-DD") });
    this.setState({ selectedDateApiDate: moment(x).format("DD/MM/YYYY") });
  };

  changeSelectedUserType = (x) => {
    //console.log(x)
    this.setState({ selectedUserType: x });
  };

  handleLimit = (rules, value, callback) => {
    if (value !== undefined) {
      if (
        parseInt(moment.duration(value[1].diff(value[0])).asDays() + 1) <=
        this.props.limit
      ) {
        callback();
        return;
      }
      callback("Date Range Cross The Limit");
      return;
    }
    callback("Date Range Cross The Limit");
    return;
  };

  handleAttendanceStatusChange = (value) => {
    let _temp = this.state.user_list;

    let user = {
      whitelisting_number: value[0].substr(-10),
      attendance_status: value[1],
    };

    _temp.push(user);

    this.setState({ user_list: _temp });
    //console.log(value)
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          className="submit-button"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
    });
  };

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        className: "name",
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Whitelisted Number",
        dataIndex: "whitelisting_number",
        key: "whitelisting_number",
        className: "whitelisting_number",
        ...this.getColumnSearchProps("whitelisting_number"),
        render: (whitelisting_number) => (
          <span>
            <LinkButton
              prefix={"field_force"}
              id={whitelisting_number}
              name={<FontAwesomeIcon icon="user" />}
            />
            {whitelisting_number}
          </span>
        ),
      },
      {
        title: "Region",
        dataIndex: "region",
        key: "region",
        className: "region",
        filters: [
          { text: "BARISAL", value: "BARISAL" },
          { text: "BOGRA", value: "BOGRA" },
          { text: "CHITTAGONG", value: "CHITTAGONG" },
          { text: "COMILLA", value: "COMILLA" },
          { text: "DHAKA SOUTH", value: "DHAKA SOUTH" },
          { text: "DHAKA NORTH", value: "DHAKA NORTH" },
          { text: "KHULNA", value: "KHULNA" },
          { text: "MYMENSINGH", value: "MYMENSINGH" },
          { text: "SYLHET", value: "SYLHET" },
          { text: "RANGPUR", value: "RANGPUR" },
        ],
        filteredValue: filteredInfo.region || null,
        onFilter: (value, record) => record.region.includes(value),
      },
      {
        title: "Area",
        dataIndex: "area",
        key: "area",
        className: "area",
      },
      {
        title: "Territory",
        dataIndex: "territory",
        key: "territory",
        className: "territory",
      },
      {
        title: "Distribution House",
        dataIndex: "distributor_code",
        key: "distributor_code",
        className: "distributor_code",
      },
      {
        title: "Attendance Status",
        key: "attendance_status",
        className: "attendance_status",
        render: (record) => (
          <Select
            defaultValue={
              record.attendance_status || record[this.state.selectedDate]
            }
            style={{ width: 120 }}
            onChange={this.handleAttendanceStatusChange}
            disabled={
              this.state.selectedDate == this.state.today_chk ? false : true
            }
          >
            <Select.Option
              style={{ fontSize: "10px" }}
              key="1"
              value={[record.whitelisting_number, "PRESENT"]}
            >
              PRESENT
            </Select.Option>
            {/* <Select.Option
              style={{ fontSize: "10px" }}
              key="2"
              value={[record.whitelisting_number, "APPS PROBLEM"]}
            >
              APPS PROBLEM
            </Select.Option>
            <Select.Option
              style={{ fontSize: "10px" }}
              key="3"
              value={[record.whitelisting_number, "HANDSET PROBLEM"]}
            >
              HANDSET PROBLEM
            </Select.Option>
            <Select.Option
              style={{ fontSize: "10px" }}
              key="4"
              value={[record.whitelisting_number, "HANDSET LOST"]}
            >
              HANDSET LOST
            </Select.Option>
            <Select.Option
              style={{ fontSize: "10px" }}
              key="5"
              value={[record.whitelisting_number, "NATURAL CALAMITY"]}
            >
              NATURAL CALAMITY
            </Select.Option> */}
            <Select.Option
              style={{ fontSize: "10px" }}
              key="6"
              value={[record.whitelisting_number, "LEAVE"]}
            >
              LEAVE
            </Select.Option>
            <Select.Option
              style={{ fontSize: "10px" }}
              key="7"
              value={[record.whitelisting_number, "VACANT"]}
            >
              VACANT
            </Select.Option>
            {/* <Select.Option
              style={{ fontSize: "10px" }}
              key="8"
              value={[record.whitelisting_number, "FIELD ATTACHMENT"]}
            >
              FIELD ATTACHMENT
            </Select.Option>
            <Select.Option
              style={{ fontSize: "10px" }}
              key="9"
              value={[record.whitelisting_number, "GOVERNMENT RESTRICTION"]}
            >
              GOVERNMENT RESTRICTION
            </Select.Option> */}
            <Select.Option
              style={{ fontSize: "10px" }}
              key="10"
              value={[record.whitelisting_number, "ABSENT"]}
            >
              ABSENT
            </Select.Option>
            <Select.Option
              style={{ fontSize: "10px" }}
              key="11"
              value={[record.whitelisting_number, "ON CALL DUTY"]}
            >
              ON CALL DUTY
            </Select.Option>
          </Select>
        ),
      },
    ];

    return (
      <DataConsumer>
        {({ attendanceActionTableData, update }) => (
          <React.Fragment>
            <Row className="table-container">
              <Row className="form-date-range-elasticsearch">
                <Collapse
                  className="attendance-file-upload"
                  bordered={false}
                  defaultActiveKey={["1"]}
                  accordion
                >
                  <Panel header="Search" key="1">
                    <Search
                      updateFunction={update}
                      updateVar="attendanceActionTableData"
                      apiName="ATTENDANCE_ACTION_TABLE"
                      user="Merchandiser"
                      limit="31"
                      changeSelectedDate={this.changeSelectedDate}
                      changeSelectedUserType={this.changeSelectedUserType}
                    />
                  </Panel>
                  <Panel header="Bulk Upload" key="2">
                    <FileUpload update={update} />
                  </Panel>
                </Collapse>
              </Row>
              <Table
                updateFunction={update}
                updateVar="attendanceActionTableData"
                link="ATTENDANCE_ACTION_TABLE"
                value={[this.state.today, this.state.today, "0"]}
                data={attendanceActionTableData}
                col={columns}
                pagination={true}
                selectedDate={this.state.selectedDate}
                onChange={this.handleChange}
              />

              <Button
                className="submit-button"
                onClick={() => {
                  var formData = new FormData();
                  var loading = message.loading("Saving...", 0);
                  //console.log(this.state.user_list)
                  formData.append(
                    "attendance_data",
                    JSON.stringify(this.state.user_list)
                  );
                  axios
                    .post(
                      Api.HOST +
                        Api.UPDATE_ATTENDANCE_STATUS +
                        "/" +
                        this.state.selectedDateApiDate,
                      formData,
                      {
                        headers: {
                          Authorization: "JWT " + localStorage.getItem("token"),
                        },
                      }
                    )
                    .then((res) => {
                      setTimeout(loading, 0);
                    })
                    .catch((error) => {
                      setTimeout(loading, 0);
                    });
                }}
              >
                Save All Changes
              </Button>
            </Row>
          </React.Fragment>
        )}
      </DataConsumer>
    );
  }
}
