import * as XLSX from 'xlsx'

export const ColumnData = (ws, columnName) => {
    var data = []
    var range = XLSX.utils.decode_range(ws['!ref'])
    var R = range.s.r     
    for(R = range.s.r+1; R <= range.e.r; ++R) {
        var cell_1 = ws[XLSX.utils.encode_cell({c:columnName[0], r:R})]
        var cell_2 = ws[XLSX.utils.encode_cell({c:columnName[1], r:R})]
        data.push({cell_1, cell_2})
    }
    
    return data
}