// THIS FUNCTION IS CALLED EVERYTIME A PAGE IS CHANGED ON THEIR componentDidMount() lifecycle method
// USERNAME AND PAGE URL IS SENT

import axios from "axios";
import API from "../dataProvider/api_list.json";

export const publishMessage = () => {
  const username = window.localStorage.user_name;
  const url = window.location.href;
  const data = {
    username: username,
    url: url,
  };

  axios
    .post(API.POST_USER_TRACKER, data)
    .then((res) => console.log(`Event posted, Response: ${res}`))
    .catch((err) => console.log(err));
  //console.log(data)
};
