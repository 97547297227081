export const Call_validation_users = [
  "islam.mohaimenul@bkash.com",
  "rahan.ahmed@bkash.com",
  "meer.ali@bkash.com",
  "mayen.uddin@bkash.com",
  "tanveer.zaman@bkash.com",
  "aneek.peris@bkash.com",
  "farha.khanam@bkash.com",
  "shaedul.alam@bkash.com",
  "moidul.khan@bkash.com",
  "salah.uddin@bkash.com",
  "partha.halder@bkash.com",
  "ps06011.ahsan@bkash.com",
  "ps01001.debasree@bkash.com",
  "abhijit.roy@bkash.com",
  "developer",
  "abrar.hossain@bkash.com",
  "abhijit.roy@bkash.com",
  "partha.bhattacharjee@bkash.com",
  "kabir.ahasanul@bkash.com",
  "sadequl.majid@bkash.com",
  "md.parvez@bkash.com",
  "atiqur.rahman@bkash.com",
  "zahidul.haque@bkash.com",
  "kazi.byazid@bkash.com",
  "niaz.khan@bkash.com",
  "abid.chowdhury@bkash.com",
  "anik.hasan@bkash.com",
  "zisan.annie@bkash.com",
  "najnin.islam@bkash.com",
  "iftekhar.khan@bkash.com",
  "ahmedur.bhuiyan@bkash.com",
  "mizanur.rahman@bkash.com",
  "farzana.yasmin@bkash.com",
  "ashik.khoda@bkash.com",
  "sumaiya.bristy@bkash.com",
  "sabrina.esha@bkash.com",
  "bany.ahmed@bkash.com",
  "md.rahaman@bkash.com",
  "sharif.junaed@bkash.com",
  "syham.pranto@bkash.com",
  "sakiul.arafin@bkash.com",
  "latif.khan@bkash.com",
  "abrar.chy@bkash.com",
  "sabbir.parvez@bkash.com",
  "ibnul.chowdhury@bkash.com",
  "ayub.ali@bkash.com",
  "md.akib@bkash.com",
  "nafis.dastagir@bkash.com",
  "nure.arafat@bkash.com",
  "enzamul.haque@bkash.com",
  "omar.faruk@bkash.com",
  "anindya.paul@bkash.com",
  "elias.tushar@bkash.com",
  "sharif.khan@bkash.com",
  "adeeb.iqbal@bkash.com",
  "md.yemon@bkash.com",
  "muktasid.islam@bkash.com",
  "iftekhar.zaman@bkash.com",
  "farhana.akter@bkash.com",
  "tamhid.hossain@bkash.com",
  "mahmud.sultan@bkash.com",
  "sabrina.kabir@bkash.com",
  "rafsan.zaman@bkash.com",
  "md.jahan@bkash.com",
  "md.mahmood@bkash.com",
  "md.efty@bkash.com",
  "sajjadul.shagor@bkash.com",
  "asiful.islam@bkash.com",
  "asalun.arnob@bkash.com",
  "mirza.shickuzzaman@bkash.com",
  "rabbi@marketexpressbd.com",
  "md.fattah@bkash.com",
];

export const MenuList = (function () {
  const regularItems = [
    // { name: "Dashboard", link: "/", icon: "poll" },
    { name: "Dashboard", link: "/", icon: "poll" },

    { name: "POSM Dashboard", link: "/posm_dashboard", icon: "poll" },

    { name: "Reports", link: "/reports", icon: "file-excel" },
    { name: "Route Plan", link: "/route_plan", icon: "map-marked-alt" },
    { name: "Calls", link: "/calls", icon: "phone" },
    {
      name: "Validation",
      link: "/calls-validation",
      icon: "phone",
      userBase: "Trade/Super",
    },
    { name: "Outlets", link: "/outlets", icon: "store-alt" },
    { name: "Leave Management", link: "/leave_management", icon: "poll" },

    //{ name: "Image Recognition", link: "/image_recognition", icon: "image" },
    //{ name: "Upload Route Plan", link: "/upload_route_plan", icon: "route" },

    { name: "POSM Reporting", link: "/posm_reporting", icon: "file-excel" },
    { name: "Inventory", link: "/inventory_portal", icon: "file-excel" },
  ];

  if (localStorage.getItem("uploader") === "true") {
    return [
      ...regularItems,
      {
        name: "Daily Route Plan",
        link: "/daily_route_plan",
        icon: "map-marked-alt",
      },
    ];
  }

  return regularItems;
})();

export const SubMenuList = [
  {
    name: "Field Force",
    item: [
      {
        name: "Selfie Attendance",
        link: "/selfie_attendance",
        icon: "mobile-alt",
      },
      //{ name: "Leave/Absent", link: "/leave_absent", icon: "user-slash" },
      //{ name: "All Field Forces", link: "/field_force", icon: "users" },
    ],
    icon: "user",
  },
  /*{
    name: "Summary",
    item: [
      { name: "Total Calls", link: "/total_calls", icon: "tty" },
      { name: "Today's Calls", link: "/todays_calls", icon: "phone-volume" },
    ],
    icon: "poll-h",
  },*/
  /*{
    name: "Data Vizualization",
    item: [
      // { name: "Field Activity",link: "/field_activity",icon: "running" },
      // { name: "QR Trend ", link: "/qr_trend", icon: "qrcode" },
      // { name: "QR Count", link: "/qr_count", icon: "qrcode" },
      // { name: "Merchandiser Calls", link: "/merchandiser_calls", icon: "mobile-alt" },
      // { name: "Supervisor Calls", link: "/supervisor_calls", icon: "mobile-alt" }//sgfdsfs

      { name: "SOV", link: "/sov", icon: "qrcode" },
      // { name: "Temp", link: "/temp", icon: "qrcode" },
    ],
    icon: "chart-bar",
  },*/
  {
    name: "HR Module",
    item: [
      { name: "Attendance", link: "/attendance", icon: "running" },
      //{ name: "KPI ", link: "/kpi", icon: "qrcode" },
    ],
    icon: "chart-bar",
  },
];

export default {
  MenuList,
  SubMenuList,
  Call_validation_users,
};
