import React from "react";
import { Form, Icon, Input, Button, Card, message } from "antd";
import Api from "./../../dataProvider/api_list.json";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { withRouter } from "react-router-dom";
import biponon from "./biponon.png";
import Footer from "../../component/Footer/Footer";
import axiosApiInstance from "../../network/customAxiosClient";

//for inventory portal
const inventory_user_types = {
  computer_operator: "CO",
  distribution_manager: "DM",
  territory_manager: "TM",
  advance: "Trade/Super",
};

class LoginForm extends React.Component {
  getInfo = () => {
    axiosApiInstance
      .get(
        "https://biponon-management.intelligentmachines.xyz/bkash-biponon/users/api/v1/me"
      )
      .then((res) => {
        localStorage.setItem("uploader", res.data.uploader);
        localStorage.setItem("validator", res.data.validator);
        this.props.history.push("/");
      })
      .catch((err) => console.log(err));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let _this = this;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const loading = message.loading("Authenticating...", 0);
        // .post(Api.HOST + Api.LOGIN, {
        axios
          .post(
            "https://biponon-management.intelligentmachines.xyz/bkash-biponon/auth/token",
            {
              username: values.userName,
              password: values.password,
            }
          )
          .then(function (response) {
            //console.log(response.data);
            localStorage.setItem("user_name", values.userName);
            localStorage.setItem(
              "user_type",
              values.userName.split("@")[0].split("-")[0]
            );
            localStorage.setItem(
              "I_U",
              inventory_user_types[response.data.type]
            );
            localStorage.setItem(
              "user_location",
              values.userName.split("@")[0].split("-")[1]
            );
            localStorage.setItem("token", response.data.access);
            localStorage.setItem("refresh_token", response.data.refresh);

            localStorage.setItem("varify", true);
            var decoded = jwtDecode(response.data.access);
            localStorage.setItem(
              "decoded",
              new Date(decoded.exp).toLocaleDateString()
            );
            _this.getInfo();
            setTimeout(loading, 0);
          })
          .catch((error) => {
            setTimeout(loading, 0);
            if (error.response) {
              message.error("User Not Found", 2);
            } else if (error.request) {
              message.error("User Not Found", 2);
            } else {
              message.error("User Not Found", 2);
            }
          });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="login-form-container">
        <Card className="login-form-card">
          <div
            className="login-image"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={biponon} alt="biponon login" />
            <p
              style={{
                marginBottom: 0,
                fontSize: 20,
                marginTop: 5,
                fontWeight: 400,
                opacity: 0.7,
              }}
            >
              Competitive edge as a service
            </p>
          </div>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator("userName", {
                rules: [
                  { required: true, message: "Please input your username!" },
                ],
              })(
                <Input
                  className="login-username"
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="User ID"
                />
              )}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Please input your Password!" },
                ],
              })(
                <Input
                  className="login-password"
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-button login-submit-button"
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Footer />
      </div>
    );
  }
}

const WrappedLoginForm = Form.create({ name: "normal_login" })(LoginForm);
export default withRouter(WrappedLoginForm);
