import React from "react";
import { Form, Button, DatePicker, Select, Alert } from "antd";
import Controller from "./../dataProvider/dataCollector/collector";
import Generator from "./../dataProvider/_generator";
import { DataConsumer } from "./../dataProvider/controller";
import moment from "moment";
import disabledDate from "./../utility/disableDate";
const { RangePicker } = DatePicker;

class ElasticSearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      day: moment(new Date()).format("DD/MM/YYYY"),
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (values.whitelistedNumber && values.datePicker) {
        this.props.changeSelectedDate(values.datePicker);
        this.props.changeSelectedUserType(values.whitelistedNumber);
        //console.log(values)
        Controller(
          1,
          this.props.updateFunction,
          this.props.updateVar,
          [],
          Generator(this.props.apiName, [
            moment(values.datePicker).format("DD/MM/YYYY"),
            moment(values.datePicker).format("DD/MM/YYYY"),
            values.whitelistedNumber,
          ])
        );
      }
    });
  };

  handleLimit = (rules, value, callback) => {
    if (value !== undefined) {
      if (
        parseInt(moment.duration(value[1].diff(value[0])).asDays() + 1) <=
        parseInt(this.props.limit)
      ) {
        callback();
        return;
      }
      callback("Date Range Cross The Limit");
      return;
    }
    callback("Date Range Cross The Limit");
    return;
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout="inline" onSubmit={this.handleSubmit}>
        {/* <Form.Item>
          {getFieldDecorator("datePicker", {
            rules: [{ required: true,
              validator: this.handleLimit, 
              message: `Please Select Valid Date Range (Valid For ${this.props.limit} Days)!` 
            }]
          })(
            <RangePicker
              className="biponon-daterange"
              format="DD/MM/YYYY"
              style={{ width: "250px" }}
              renderExtraFooter={() => 
                <div style={{textAlign:"center"}}>
                  <Alert 
                    style={{textAlign:'center'}} 
                    message={`Date Range Limit is ${this.props.limit} Days`} 
                    type="warning" 
                  />
                </div>
              }
              disabledDate={disabledDate}
            />
          )} */}
        <Form.Item>
          {getFieldDecorator("datePicker", {
            rules: [{ required: true, message: "Please select a date !" }],
          })(
            <DatePicker
              className="biponon-datepicker"
              format="DD/MM/YYYY"
              style={{ width: "250px" }}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("whitelistedNumber", {
            rules: [
              {
                required: true,
                message: `Please Select Field Force Type`,
              },
            ],
          })(
            <Select
              showSearch
              placeholder="Field Force"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "250px" }}
            >
              <Select.Option key={"MERCHANDISER"} value={"Merchandiser"}>
                MERCHANDISER
              </Select.Option>
              <Select.Option key={"SUPERVISOR"} value={"Supervisor"}>
                SUPERVISOR
              </Select.Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <Button className="submit-button" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedElasticSearchForm = Form.create({
  name: "horizontal_login",
})(ElasticSearchForm);

export default WrappedElasticSearchForm;
