import api from "./api_list.json";

export default function Generator(apiName, value, host = "default") {
  var parameter = "";
  for (let i = 0; i < value.length; i++) {
    if (value[i] !== "") parameter = parameter + "/" + value[i];
  }

  return host === "default"
    ? api.HOST + api[apiName] + parameter
    : host + api[apiName].replace("api/v1/", "") + parameter;
}
