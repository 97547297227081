import React from "react"
import { Tabs, Row } from "antd"
import AttendanceHistoryTable from "./Component/attendanceTable"
import AttendanceActionTable from "./Component/attendanceActionTable"
import { publishMessage } from "../../../utility/publish-event-message"
import Footer from "../../../component/Footer/Footer"

const { TabPane } = Tabs

export default class Attendance extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDate: "",
    }
  }

  componentDidMount() {
    publishMessage()
  }

  render() {
    return (
      <Row className="table-container">
        <Row gutter={16} className="title-container">
          <h3>Attendance </h3>
        </Row>
        <Tabs defaultActiveKey="1" className="biponon-tabs">
          <TabPane tab="Update Attendance" key="1">
            <AttendanceActionTable />
          </TabPane>
          <TabPane tab="Attendance History" key="2">
            <AttendanceHistoryTable />
          </TabPane>
        </Tabs>
        <Footer />
      </Row>
    )
  }
}
