import React from "react";
import Logo from "./../style/images/biponon.png";
require("./../style/header.css");

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Header = (props) => {
  return (
    <header className="header">
      <nav className="header-navbar">
        <ul className="header-navbar-ul">
          <li className="header-navbar-logo">
            <img src={Logo} width="130px" height="35px" alt="Logo" />
            <p style={{ marginBottom: 0, fontWeight: 400 }}>
              Competitive edge as a service
            </p>
          </li>

          <li
            className="header-navbar-logout"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 20,
              padding: "15px 0px 15px 32px",
            }}
          >
            {localStorage.getItem("user_name") && (
              <p
                style={{
                  margin: 0,
                  fontFamily: "monospace",
                  fontWeight: 500,
                  color: "#e32b7c",
                }}
              >
                Hello,{" "}
                {capitalizeFirstLetter(localStorage.getItem("user_name"))}!
              </p>
            )}
            <button
              type="button"
              className="btn logout-btn"
              onClick={() => {
                localStorage.clear();
                props.history.push("/login");
              }}
            >
              Log out
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
