import React from "react";
import { Row, Col, Button, Icon, Input } from "antd";
import { DataConsumer } from "../../../../dataProvider/controller";
import Table from "./../../../../component/simpleTable";
import moment from  "moment";
import LinkButton from "./../../../../component/linkButton";
import Search from "./kpiFormDateRangeElasticSearch";
import Highlighter from "react-highlight-words";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './kpiTable.css';

export default class KpiTable extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      today: moment(new Date()).format("DD/MM/YYYY")
    };
  }

  handleLimit = (rules, value, callback) => {
    if(value !== undefined){
      if(parseInt(moment.duration(value[1].diff(value[0])).asDays()+1)<=this.props.limit){
        callback();
        return;
      }
      callback("Date Range Cross The Limit")
      return;
    }
    callback("Date Range Cross The Limit")
    return;
  }
  
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          className="submit-button"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters
    });
  };

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};

    const columns = [{
        title: "Name",
        dataIndex: "name",
        key: "name",
        className: "name",
        ...this.getColumnSearchProps("name")
      },{
        title: "Whitelisted Number",
        dataIndex: "whitelisting_number",
        key: "whitelisting_number",
        className: "whitelisting_number",
        ...this.getColumnSearchProps("whitelisting_number"),
        render: whitelisting_number => (
          <span>
            <LinkButton
              prefix={"field_force"}
              id={whitelisting_number}
              name={<FontAwesomeIcon icon="user" />}
            />
            {whitelisting_number}
          </span>
        )
      },{
        title: "Type",
        dataIndex: "type",
        key: "type",
        className: "type",
        ...this.getColumnSearchProps("type"),
      },{
        title: "Region",
        dataIndex: "region",
        key: "region",
        className: "region",
        filters: [
          { text: "BARISAL", value: "BARISAL" },
          { text: "BOGRA", value: "BOGRA" },
          { text: "CHITTAGONG", value: "CHITTAGONG" },
          { text: "COMILLA", value: "COMILLA" },
          { text: "DHAKA SOUTH", value: "DHAKA SOUTH" },
          { text: "DHAKA NORTH", value: "DHAKA NORTH" },
          { text: "KHULNA", value: "KHULNA" },
          { text: "MYMENSINGH", value: "MYMENSINGH" },
          { text: "SYLHET", value: "SYLHET" },
          { text: "RANGPUR", value: "RANGPUR" }
        ],
        filteredValue: filteredInfo.region || null,
        onFilter: (value, record) => record.region.includes(value)
      },{
        title: "Area",
        dataIndex: "area",
        key: "area",
        className: "area"
      },{
        title: "Territory",
        dataIndex: "territory",
        key: "territory",
        className: "territory"
      },{
        title: "Distribution House",
        dataIndex: "distributor_code",
        key: "distributor_code",
        className: "distributor_code"
      },{
        title: "Distributor",
        dataIndex: "Distributor",
        key: "Distributor",
        className: "Distributor"
      },
      {
        title: "Outlet",
        className: "Outlet",
        children:[
          {
            title: "Not Found",
            dataIndex: "outlet_not_found",
            key: "outlet_not_found",
            className: "outlet_not_found"
          },{
            title: "Found Open",
            dataIndex: "found_open",
            key: "found_open",
            className: "found_open"
          },{
            title: "Found Closed",
            dataIndex: "found_closed",
            key: "found_closed",
            className: "found_closed"
          }
        ]
      },{
        title: "QR",
        className: "QR",
        children:[
          {
            title: "Found",
            dataIndex: "qr_found",
            key: "qr_found",
            className: "qr_found"
          },{
            title: "Not Found",
            dataIndex: "qr_not_found",
            key: "qr_not_found",
            className: "qr_not_found"
          },{
            title: "Not Applicable",
            dataIndex: "qr_not_applicable",
            key: "qr_not_applicable",
            className: "qr_not_applicable"
          }
        ]
      },{
          title: "Campaign",
          className: "Campaign",
          children:[
            {
              title: "Effective Call Target",
              dataIndex: "Effective_CallTarget_campaign",
              key: "Effective_CallTarget_campaign",
              className: "Effective_CallTarget_campaign"
            },{
              title: "Actual Call Target",
              dataIndex: "Actual_CallTarget_campaign",
              key: "Actual_CallTarget_campaign",
              className: "Actual_CallTarget_campaign"
            },{
              title: "Achievement",
              dataIndex: "Achievement_campaign",
              key: "Achievement_CallTarget_campaign",
              className: "Achievement_CallTarget_campaign"
            }
          ]
        },{
          title: "Validation",
          className: "Validation",
          children:[
            {
              title: "Effective Call Target",
              dataIndex: "Effective_CallTarget_validation",
              key: "Effective_CallTarget_validation",
              className: "Effective_CallTarget_validation"
            },
            {
              title: "Actual Call Target",
              dataIndex: "Actual_CallTarget_validation",
              key: "Actual_CallTarget_validation",
              className: "Actual_CallTarget_validation"
            },
            {
              title: "Achievement",
              dataIndex: "Achievement_validation",
              key: "Achievement_CallTarget_validation",
              className: "Achievement_CallTarget_validation"
            },
          ]
        },{
          title: "Regular",
          className: "Regular",
          children:[
            {
              title: "Effective Call Target",
              dataIndex: "Effective_CallTarget_regular",
              key: "Effective_CallTarget_regular",
              className: "Effective_CallTarget_regular"
            },
            {
              title: "Actual Call Target",
              dataIndex: "Actual_CallTarget_regular",
              key: "Actual_CallTarget_regular",
              className: "Actual_CallTarget_regular"
            },
            {
              title: "Achievement",
              dataIndex: "Achievement_regular",
              key: "Achievement_CallTarget_regular",
              className: "Achievement_CallTarget_regular"
            },
	        ]
        },{
          title: "Ad Hoc",
          className: "Ad_Hoc",
          children:[
            {
              title: "Effective Call Target",
              dataIndex: "Effective_CallTarget_ad_hoc",
              key: "Effective_CallTarget_ad_hoc",
              className: "Effective_CallTarget_ad_hoc"
            },{
              title: "Actual Call Target",
              dataIndex: "Actual_CallTarget_ad_hoc",
              key: "Actual_CallTarget_ad_hoc",
              className: "Actual_CallTarget_ad_hoc"
            },{
              title: "Achievement",
              dataIndex: "Achievement_ad_hoc",
              key: "Achievement_CallTarget_ad_hoc",
              className: "Achievement_CallTarget_ad_hoc"
            }]
        },{
          title: "Outlet Call",
          className: "Outlet_Call",
          children:[
            {
              title: "Effective Call Target",
              dataIndex: "Effective_CallTarget_specific_outlet_call",
              key: "Effective_CallTarget_specific_outlet_call",
              className: "Effective_CallTarget_specific_outlet_call"
            },
            {
              title: "Actual Call Target",
              dataIndex: "Actual_CallTarget_specific_outlet_call",
              key: "Actual_CallTarget_specific_outlet_call",
              className: "Actual_CallTarget_specific_outlet_call"
            },
            {
              title: "Achievement",
              dataIndex: "Achievement_specific_outlet_call",
              key: "Achievement_CallTarget_specific_outlet_call",
              className: "Achievement_CallTarget_specific_outlet_call"
            },
          ]
        }
      ];


    return (
      <DataConsumer>
        {({ kpi, update }) => (
          <React.Fragment>
            <Row className="table-container">
              <Row className="title-conatiner">
                <Col xs={24} sm={24} md={18} lg={18}>
                  <h3>Kpi Calculation</h3>
                </Col>
              </Row>
              <Row className="form-date-range-elasticsearch">
                <Search
                  updateFunction={update}
                  updateVar="kpi"
                  apiName="KPI"
                  user="0"
                  limit="31"
                />
              </Row>
              <Table
                style={{  whiteSpace: "nowrap" }}
                updateFunction={update}
                updateVar="kpi"
                link="KPI"
                value={[this.state.today, this.state.today, "0"]}
                data={kpi}
                col={columns}
                pagination={true}
                onChange={this.handleChange}
              />
            </Row>
          </React.Fragment>
        )}
      </DataConsumer>
    );
  }
}
