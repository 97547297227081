import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

export default class LinkButton extends React.Component {
  render() {
    return (
      <Button
        style={{
          padding: 0,
          borderRadius: 4,
          border: "none",
          marginBottom: 20,
        }}
        // className="submit-button"
        // htmlType="submit"
        // shape="circle"
        // size={"large"}
        type="text"
      >
        <Link
          to={`/${this.props.prefix}/${this.props.id}`}
          target="_blank"
          style={{
            color: "#e2146e",
            fontWeight: 800,
            fontFamily: "Roboto Flex",
            letterSpacing: ".1rem",
          }}
          // className="nav-link  "
        >
          <span>
            <div
              className="phone-numbers-container"
              style={{ marginBottom: 20 }}
            >
              <p
                className="phone-number-subtitle"
                style={{ marginBottom: 0, fontWeight: 500 }}
              >
                {this.props.name}
              </p>
              <p className="phone-number-title">{this.props.id}</p>
            </div>
          </span>
        </Link>
      </Button>
    );
  }
}
