import React from "react";
import { Table, Alert } from "antd";
import Loading from "./../../../../content/spin";

require("./../../../../style/table.css");

export default class simpleTable extends React.Component {
  onChange() {}

  render() {
    var myEmptyText = <Loading/> 
    return (
      <Table
        className="simple-table"
        dataSource={this.props.data}
        columns={this.props.columns}
        scroll={{ x: 1500 }}
        size="middle"
        bordered={false}
        locale={{ emptyText: myEmptyText }}
        pagination={this.props.pagination}
        onChange={this.props.onChange || this.onChange}
      />
    );
  }
}
