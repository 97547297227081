import React, { Component } from "react";
import "./App.css";
import { withRouter } from "react-router-dom";
import Landing from "./_pages/Landing/landing";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTrophy,
  faDotCircle,
  faUser,
  faHome,
  faPoll,
  faStoreAlt,
  faIdCardAlt,
  faTags,
  faPhone,
  faFileExcel,
  faPollH,
  faUsers,
  faMobileAlt,
  faUserSlash,
  faMapMarkedAlt,
  faPhoneVolume,
  faHandshake,
  faAd,
  faQuestion,
  faMapMarker,
  faQrcode,
  faClock,
  faCalendarAlt,
  faInfoCircle,
  faTty,
  faImage,
  faRoute,
  faRunning,
  faChartBar,
  faFileUpload,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Api from "./dataProvider/api_list";
import { message } from "antd";
library.add(
  faTrophy,
  faDotCircle,
  faUser,
  faHome,
  faPoll,
  faStoreAlt,
  faIdCardAlt,
  faTags,
  faPhone,
  faFileExcel,
  faPollH,
  faUsers,
  faMobileAlt,
  faUserSlash,
  faMapMarkedAlt,
  faPhoneVolume,
  faHandshake,
  faAd,
  faQuestion,
  faMapMarker,
  faQrcode,
  faClock,
  faCalendarAlt,
  faTty,
  faImage,
  faRoute,
  faRunning,
  faChartBar,
  faFileUpload,
  faEye
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { loading: false };
  }
  componentWillMount() {
    let _this = this;
    _this.setState({
      loading: false,
    });
    if (localStorage.getItem("token")) {
      _this.props.history.push("/");
    } else {
      _this.props.history.push("/login");
    }
  }
  render() {
    return (
      <div className="App">
        <Landing {...this.props} loading={this.state.loading} />
      </div>
    );
  }
}

export default withRouter(App);
