import React from "react";
import MainFrame from "../../utility/mainFrame";
import WrappedLoginForm from "./../Login/login";
import Loading from './../../content/spin'
import { withRouter, Redirect } from 'react-router-dom';

class Landing extends React.Component {
  render() {
    
    if (localStorage.getItem("token") && localStorage.getItem("varify")) return <MainFrame/>
    return <Redirect to='/login'/>
    
  }
}

export default withRouter(Landing);
