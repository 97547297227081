import * as XLSX from 'xlsx'

export const FileColumnList = (ws) =>{
    var columns = [];
    var range = XLSX.utils.decode_range(ws['!ref']);
    var C, R = range.s.r; 
    
    for(C = range.s.c; C <= range.e.c; ++C) {
        var cell = ws[XLSX.utils.encode_cell({c:C, r:R})] /* find the cell in the first row */
        var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
        if(cell && cell.t) hdr = XLSX.utils.format_cell(cell);

        columns.push(hdr);
    }
    return columns
} 