import axios from "axios";
import { message } from "antd";
// test
export default function Collector(
  msg,
  updateFunction,
  updateVar,
  exUpdateVar,
  apiName,
  action
) {
  updateFunction(updateVar, []);
  // //console.log(exUpdateVar);
  if (exUpdateVar.length > 0)
    exUpdateVar.map((item, index) => updateFunction(item.var, []));
  if (msg === 1) var loading = message.loading("Searching", 0);

  axios
    .get(apiName, {
      headers: { Authorization: "JWT " + localStorage.getItem("token") },
    })
    .then((res) => {
      updateFunction(
        updateVar,
        res.data.dataSource || res.data.data || res.data.count
      );
      if (exUpdateVar.length > 0)
        exUpdateVar.map((item, index) =>
          updateFunction(item.var, res.data[item.data])
        );
      if (action) updateFunction(action, true);
      if (msg === 1) setTimeout(loading, 0);
    })
    .catch((error) => {
      setTimeout(loading, 0);
      if (error.response) {
        if (error.response.status === 401) {
          message.error(
            "Your Session has been Deleted, Please Log In Again",
            5
          );
          localStorage.removeItem("token");
          window.location.href = "/";
        } else message.error("Data Not Found", 4);
      }
    });
}
