import React from "react";
import { Result } from "antd";

class FallbackComponent extends React.Component {
  render() {
    return (
      <div style={{ width: "100%" }}>
        <Result status="warning" title="Something went wrong !" />
      </div>
    );
  }
}

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.clear();
    console.log(error);
    console.log(info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <FallbackComponent />;
    }

    return this.props.children;
  }
}
