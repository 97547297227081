import React from "react";
import Table from "./../content/table";
import Controller from "./../dataProvider/dataCollector/collector";
import Generator from "./../dataProvider/_generator";

export default class SimpleTable extends React.Component {
  componentDidMount() {
    Controller(
      0,
      this.props.updateFunction,
      this.props.updateVar,
      [],
      Generator(this.props.link, this.props.value)
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.props.onChange ? (
          <Table
            data={this.props.data}
            columns={this.props.col}
            pagination={this.props.pagination}
            onChange={this.props.onChange}
            bordered
          />
        ) : (
          <Table
            data={this.props.data}
            columns={this.props.col}
            pagination={this.props.pagination}
            bordered
          />
        )}
      </React.Fragment>
    );
  }
}
