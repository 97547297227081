import React from "react";
import { Table, Alert } from "antd";
import Loading from "./spin";

require("./../style/table.css");

let uniqueId = 0;
export default class simpleTable extends React.Component {
  onChange() {}

  render() {
    var myEmptyText = this.props.data == ""?<Loading/>:<Alert message="No Data" type="error" /> 
    return (
      <Table
        className="simple-table"
        dataSource={this.props.data}
        columns={this.props.columns}
        scroll={{ x: 1500 }}
        size="middle"
        bordered={false}
        locale={{ emptyText: myEmptyText }}
        pagination={this.props.pagination}
        onChange={this.props.onChange || this.onChange}
        rowKey={(record) => {
          if (!record.__uniqueId)
            record.__uniqueId = ++uniqueId;
          return record.__uniqueId;
        }}
      />
    );
  }
}
