import React from "react"
import KpiTable from "./Component/kpiTable"
import { publishMessage } from "../../../utility/publish-event-message"

export default class Kpi extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    publishMessage()
  }

  render() {
    return <KpiTable />
  }
}
