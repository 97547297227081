import React from "react";
import { create } from "zustand";

export const useBearStore = create((set) => ({
  requisitionToBeHandled: 0,
  changeRequisitionToBeHandled: (param) =>
    set((state) => ({ requisitionToBeHandled: param })),
}));

export const withStore = (BaseComponent) => (props) => {
  const store = useBearStore();
  return <BaseComponent {...props} store={store} />;
};
