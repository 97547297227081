import { WorkSheet } from "./workSheet";
import { FileColumnList } from "./fileColumnList";
import { FindColumn } from "./findColumn";
import { ColumnData } from "./columnData";

export const fileRead = (file, findColumn) =>
  new Promise((resolve) => {
    var reader = new FileReader();
    reader.onloadend = () => {
      resolve({ data: reader.result, fileName: file.name });
    };
    reader.readAsArrayBuffer(file);
  }).then((result) => {
    //console.log(result.data)
    const ws = WorkSheet(new Uint8Array(result.data));
    //console.log(ws)
    const columnList = FileColumnList(ws);
    const findColumnResult = FindColumn(columnList, findColumn);
    if (findColumnResult.length > 0) return ColumnData(ws, findColumnResult);
    else return false;
  });
