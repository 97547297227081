import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Menu } from "antd";
import { MenuList, Call_validation_users } from "./../utility/menuList.js";
import { SubMenuList } from "./../utility/menuList.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import axios from "axios";
import "./menuItem.css";
import { withStore } from "../zustandStore.js";

class menuItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inventoryPortalData: [],
    };
  }

  componentDidMount() {
    const inventoryUserType = localStorage.getItem("I_U");

    if (inventoryUserType === "DM") this.fetchRequisitionList();
  }

  fetchRequisitionList = () => {
    axios
      .get(
        `https://dev-inventory-biponon.retailvoice.xyz/demand/v1/requisition`,
        {
          headers: {
            Authorization: "JWT " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        this.props.store.changeRequisitionToBeHandled(
          res.data.filter((i) => i.action !== "DM_RECEIVED").length
        );
        this.setState({
          inventoryPortalData: res.data.filter(
            (i) => i.action !== "DM_RECEIVED"
          ),
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  checkUserListForCallValidation = (menuItem) => {
    if (menuItem.link !== "/calls-validation") return true;
    const userName = localStorage.getItem("user_name");
    return Call_validation_users.includes(userName);
  };
  checkUserForDailyRoutePlan = (menuItem) => {
    if (menuItem.link !== "/daily_route_plan") return true;
    return localStorage.getItem("uploader");
  };
  render() {
    const inventoryUserType = localStorage.getItem("I_U");
    return (
      <Menu theme="light" mode="inline" defaultSelectedKeys={[]}>
        {localStorage.getItem("user_type").toUpperCase() !== "RMM" &&
          MenuList.filter((item) => item.name !== "Leave Management")
            // .filter((i) => {
            //   if (!i.userBase) return true;
            //   if (i.userBase && i.userBase === localStorage.getItem("I_U"))
            //     return true;
            // })
            .filter(this.checkUserListForCallValidation)
            .filter(this.checkUserForDailyRoutePlan)
            .map((item, index) => {
              return (
                <Menu.Item
                  key={item.name}
                  onClick={this.props.triggerSider}
                  title={item.name}
                  theme={"light"}
                  style={{ color: "rgba(54, 43, 115, 1)" }}
                >
                  <NavLink
                    to={item.link}
                    className="nav-link"
                    style={{ position: "relative" }}
                  >
                    <FontAwesomeIcon
                      icon={item.icon}
                      className="faIcons"
                      style={{
                        marginRight: "10px",
                        transform: this.props.hidden
                          ? "scale(1.5)"
                          : "scale(1)",
                        transition: "transform 1s",
                      }}
                    />
                    {!this.props.hidden && (
                      <span className="menu-link-name">{item.name}</span>
                    )}
                    {item.link === "/inventory_portal" &&
                      this.props.store.requisitionToBeHandled !== 0 &&
                      inventoryUserType === "DM" && (
                        <p className="inventory-noti">
                          {" "}
                          {this.props.store.requisitionToBeHandled}
                        </p>
                      )}
                  </NavLink>
                </Menu.Item>
              );
            })}
        {localStorage.getItem("user_type").toUpperCase() === "RMM" &&
          MenuList.map((item, index) => {
            return (
              <Menu.Item
                key={item.name}
                onClick={this.props.triggerSider}
                title={item.name}
                theme="light"
                style={{ color: "rgba(54, 43, 115, 1)" }}
              >
                <NavLink to={item.link} className="nav-link">
                  <FontAwesomeIcon
                    icon={item.icon}
                    className="faIcons"
                    style={{
                      marginRight: "10px",
                      transform: this.props.hidden ? "scale(1.5)" : "scale(1)",
                      transition: "transform 1s",
                    }}
                  />
                  {!this.props.hidden && (
                    <span className="menu-link-name">{item.name}</span>
                  )}
                </NavLink>
              </Menu.Item>
            );
          })}
        {SubMenuList.map((items, index) =>
          items.name.toUpperCase() === "SUMMARY" &&
          (localStorage.getItem("user_type").toUpperCase() === "RM" ||
            localStorage.getItem("user_type").toUpperCase() === "AM" ||
            localStorage.getItem("user_type").toUpperCase() === "TM" ||
            localStorage.getItem("user_type").toUpperCase() === "DM") ? (
            <></>
          ) : (
            <Menu.SubMenu
              key={index}
              title={
                <div>
                  <FontAwesomeIcon
                    icon={items.icon}
                    className="faIcons"
                    theme={"light"}
                    style={{
                      marginRight: "10px",
                      transform: this.props.hidden ? "scale(1.5)" : "scale(1)",
                      transition: "transform 1s",
                    }}
                  />
                  {!this.props.hidden && (
                    <span className="menu-link-name">{items.name}</span>
                  )}
                </div>
              }
            >
              {items.item.map((item, i) => (
                <Menu.Item
                  key={item.name}
                  onClick={this.props.triggerSider}
                  title={item.name}
                  theme={"light"}
                >
                  <NavLink
                    to={item.link}
                    className="nav-link"
                    style={{
                      marginLeft: this.props.hidden ? 0 : -20,
                      transform: "scale(.9)",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={item.icon}
                      className="faIcons"
                      style={{
                        marginRight: "10px",
                        transform: this.props.hidden
                          ? "scale(1.5)"
                          : "scale(1)",
                        transition: "transform 1s",
                      }}
                    />
                    {<span className="menu-link-name">{item.name}</span>}
                  </NavLink>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          )
        )}
      </Menu>
    );
  }
}

export default withStore(menuItem);
